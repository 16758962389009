import {
  Box,
  Button,
  ButtonGroup,
  Flex,
  Icon,
  Text,
  useDisclosure,
} from "@chakra-ui/core";
import { graphql, PageProps, Link } from "gatsby";
import BackgroundImage from "gatsby-background-image";
import React from "react";
import { HomePageLayoutWrapper } from "../components/HomePageLayoutWrapper";
import { ApplyModal } from "../components/modals/ApplyModal";

interface DataProps {
  file: {
    childImageSharp: {
      fluid: any;
    };
  };
}

export const query = graphql`
  query {
    file(relativePath: { eq: "coretruck.png" }) {
      childImageSharp {
        fluid(maxWidth: 1100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

const HomePage: React.FC<PageProps<DataProps>> = ({ data }) => {
  return (
    <HomePageLayoutWrapper>
      {/* patterned stripes rectangle */}
      <Flex
        w={["200px", "500px", "1080px"]}
        h={["400px", "550px"]}
        position="absolute"
        right="0"
        bottom="0"
        background="linear-gradient(135deg, #0e1037 45.45%, #ababab 45.45%, #ababab 50%, #0e1037 50%, #0e1037 95.45%, #ababab 95.45%, #ababab 100%)"
        backgroundSize="31.11px 31.11px"
      />
      <Box
        position="relative"
        h={["500px", "810px"]}
        w={["300px", "1185px"]}
        zIndex={3}
      >
        <Box position="absolute" h={["470px", "750px"]} w={["280px", "1080px"]}>
          <BackgroundImage
            fluid={data.file.childImageSharp.fluid}
            style={{
              height: "100%",
              width: "100%",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Flex
              direction="column"
              w={["240px", "500px", "700px"]}
              ml={["24px", "24px", "285px"]}
              p={[0, 8]}
            >
              <Text
                as="h1"
                color="whitePrimary"
                fontSize={["26px", "50px", "68px"]}
                lineHeight="1.3em"
                mb={4}
              >
                Discover the Latest Innovations in <br />
                Energy & Resources.
              </Text>
              <Text as="h2" color="whitePrimary" fontSize={["12px", "20px"]}>
                Core Innovation Hub’s ecosystem map lets you discover key
                players in the Energy & Resources sector in South Australia.
              </Text>
              <Link to="/network">
                <ButtonGroup spacing={0} mt="50px">
                  <Button
                    fontSize={["12px", "14px"]}
                    borderRadius="0px"
                    color="whitePrimary"
                    backgroundColor="bluePrimary"
                    h="48px"
                    textTransform="uppercase"
                  >
                    View The Map
                  </Button>
                  <Icon
                    color="whitePrimary"
                    size="48px"
                    padding="12px"
                    name="chevron-right"
                    aria-label="apply"
                    backgroundColor="blueTertiary"
                  />
                </ButtonGroup>
              </Link>
            </Flex>
          </BackgroundImage>
        </Box>

        {/* solid blue rectangle */}
        <Flex
          mt={["28px", "54px"]}
          bg="bluePrimary"
          h={["470px", "750px"]}
          w="100%"
          zIndex={2}
        />
      </Box>
    </HomePageLayoutWrapper>
  );
};

export default HomePage;
